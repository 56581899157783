body {
  overscroll-behavior: contain;
}
.sign-wrapper {
  position: relative;
  width: 300px;
  height: 100px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.signature-pad {
  border: 1px solid #ddd;

  position: absolute;
  left: 0;
  top: 0;
  width: 300px;
  height: 100px;
  background-color: white;
}
.driver-footer {
  width: 100% !important;
}
.full-page-loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  display: none;
}
.hours-row .error {
  right: 15px !important;
  top: -20px !important;
}
/* data picker css override */
.react-datepicker__input-container label.error {
  top: -25px !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-list-item {
  font-size: 18px;
  width: 40px !important;
  height: 40px !important;
  padding: 12px;
}
.react-datepicker__time-container,
.react-datepicker__time-list,
.react-datepicker__time-box {
  width: 120px !important;
}
.react-datepicker__current-month,
.react-datepicker__year-read-view--selected-year,
.react-datepicker__month-read-view--selected-month,
.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker-time__header {
  font-size: 18px !important;
}
/* ------------- */
/* .css-1s2u09g-control.error {
  border: 1px solid red;
} */
.review-details .row > .btn {
  font-size: 13px;
  padding: 0 6px 1px 6px;
  margin-top: -10px;
}

div#equipment_name div:has(div.css-319lph-ValueContainer input.error) {
  border-color: red;
}
div#equipment_name div label.error {
  position: absolute;
  top: -15px;
}
div#equipment_name
  > div:has(div.css-319lph-ValueContainer input.error)::before {
  content: "This field is required";
  color: #eb5757 !important;
  font-size: 12px;
  position: absolute;
  top: -20px;
  right: 0;
}
